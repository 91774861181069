<template>
  <div>
    <app-section-title
      :title="props.title"
    />

    <app-card-item
      class="p-4 mt-4 col-start-1"
    >
      <slot />
    </app-card-item>
  </div>
</template>

<script setup>
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'

const props = defineProps({
  // Title of card
  title: {
    type: String,
    default: null,
  },
})
</script>
